import { IntlShape } from "gatsby-plugin-intl";

import {
  CemexThumbnail,
  FourFinanceThumbnail,
  ItsoundsThumbnail,
  MeatbonesThumbnail,
  MetrostavThumbnail,
  MoPlayThumbnail,
  WeeducateThumbnail
} from "../../assets/images/Images";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";

export type SuccessStoriesCategoriesT =
  | "fintech"
  | "construction"
  | "entertaining"
  | "e-commerce";

export type SuccessStoriesFilterItemT = SuccessStoriesCategoriesT | "all";
export type SuccessStoriesIdsT =
  | "metrostav"
  | "cemex"
  | "moplay"
  | "4finance"
  | "weeducate"
  | "meatbones"
  | "itsounds";
type SuccessStoriesItemsT = SuccessStoriesItemT[];

export type SuccessStoriesItemT = {
  id: SuccessStoriesIdsT;
  technologies: string[];
  title: string;
  thumbnail: string;
  color: string;
  description?: string;
  category: SuccessStoriesCategoriesT;
  homepage?: boolean;
};

export const categories: SuccessStoriesFilterItemT[] = [
  "all",
  "fintech",
  "construction",
  "entertaining",
  "e-commerce"
];

type getStoriesT = (intl?: IntlShape) => SuccessStoriesItemsT;
export const getStories: getStoriesT = intl => {
  return [
    {
      id: "metrostav",
      technologies: ["Website", "Ruby on Rails", "Dev&Design"],
      title: "Metrostav",
      thumbnail: MetrostavThumbnail,
      color: "#c50000",
      description:
        intl &&
        intl.formatMessage({
          id: "home.successStories.descriptions.metrostav"
        }),
      category: "construction",
      homepage: true
    },
    {
      id: "cemex",
      technologies: ["Web app", "React", "Dev&Design"],
      title: "CEMEX GO",
      thumbnail: CemexThumbnail,
      color: "#13aad6",
      description:
        intl &&
        intl.formatMessage({
          id: "home.successStories.descriptions.cemex"
        }),
      category: "construction",
      homepage: true
    },
    {
      id: "moplay",
      technologies: ["mobile&web app", "React Native", "Development"],
      title: "MoPlay",
      thumbnail: MoPlayThumbnail,
      color: "#9b9b9b",
      description:
        intl &&
        intl.formatMessage({
          id: "home.successStories.descriptions.moplay"
        }),
      category: "entertaining",
      homepage: true
    },
    {
      id: "4finance",
      technologies: ["Web app", "React", "Dev&Design"],
      title: "4finance",
      thumbnail: FourFinanceThumbnail,
      color: "#2f5dad",
      description:
        intl &&
        intl.formatMessage({
          id: "home.successStories.descriptions.4finance"
        }),
      category: "fintech",
      homepage: true
    },
    {
      id: "weeducate",
      technologies: ["mobile app", "React Native", "Dev&Design"],
      title: "Weeducate",
      thumbnail: WeeducateThumbnail,
      color: "#86f0b4",
      description:
        intl &&
        intl.formatMessage({
          id: "home.successStories.descriptions.weeducate"
        }),
      category: "entertaining"
    },
    {
      id: "meatbones",
      technologies: ["Website", "React", "Dev&Design"],
      title: putEmptyLineInsteadSpace("Meat &Bones", 1),
      thumbnail: MeatbonesThumbnail,
      color: "#3b7b06",
      description:
        intl &&
        intl.formatMessage({
          id: "home.successStories.descriptions.meatbones"
        }),
      category: "e-commerce",
      homepage: true
    },
    {
      id: "itsounds",
      technologies: ["Website", "React", "Dev&Design"],
      title: "itSOUNDS",
      thumbnail: ItsoundsThumbnail,
      color: "#af2227",
      description:
        intl &&
        intl.formatMessage({
          id: "home.successStories.descriptions.itsounds"
        }),
      category: "entertaining"
    }
  ];
};

export const getHomepageStories: getStoriesT = intl => {
  return getStories(intl).filter(item => item.homepage);
};

type getColorT = (id: SuccessStoriesIdsT) => string;
export const getColor: getColorT = id => {
  return getStories().filter(item => item.id === id)[0].color;
};
