import styled from "../styling/styled";
import { rem } from "../styling/theme";

type ActiveStylePropsT = {
  active: boolean;
};

export const BracketItem = styled.span<ActiveStylePropsT>`
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  &:after,
  &:before {
    transition: all 350ms cubic-bezier(0.515, 0.005, 0.5, 1);
    opacity: ${({ active }): number => (active ? 1 : 0)};
    position: absolute;
    color: ${({ theme }): string => theme.colors.primary};
  }
  &:after {
    content: "]";
    right: ${({ active }): string => (active ? rem(-20) : "0")};
  }
  &:before {
    content: "[";
    left: ${({ active }): string => (active ? rem(-20) : "0")};
  }
`;
