import React, { FC, useEffect, useLayoutEffect, useState } from "react";

import { SectionsQueueT } from "../hooks/useFillSections";
import { useResize } from "../hooks/useResize";
import { useScroll } from "../hooks/useScroll";
import SideBar, { MenuIconType, SIDEBAR_WIDTH } from "../partials/SideBar";
import styled from "../styling/styled";
import { pixelize } from "../styling/theme";
import { handleSetScrollSection } from "../utils/scrolledSection";
import FlexBox from "./FlexBox";
import Helmet, { HelmetKeys } from "./Helmet";
import { MenuItemsIDMenu } from "./Menu";

export type handleSetOpenT = (value: boolean) => void;

const ContentWrapper = styled.div`
  width: calc(100% - ${pixelize(SIDEBAR_WIDTH)});
  margin-left: ${pixelize(SIDEBAR_WIDTH)};
  ${({ theme }): string[] => [theme.media.maxLg]} {
    width: 100%;
    margin-left: 0;
  }
`;

const BackgroundWrapper = styled.div<BackgroundStyleT>`
  background-color: ${({ theme, lightColorVariant }): string =>
    lightColorVariant ? theme.colors.white : theme.colors.backgroundGray};
  color: ${({ theme, lightColorVariant }): string =>
    lightColorVariant ? theme.colors.darkText : theme.colors.white};
  position: relative;
  z-index: 1;
`;

type BackgroundStyleT = {
  lightColorVariant: boolean;
};

type LayoutT = {
  helmetKey: HelmetKeys;
  emptyMenu?: boolean;
  blackMenu?: boolean;
  lightColorVariant?: boolean;
  menuIconType?: MenuIconType;
  render: (
    open: boolean,
    setSections: React.Dispatch<React.SetStateAction<{} | SectionsQueueT>>
  ) => React.ReactChild;
};

const Layout: FC<LayoutT> = ({
  helmetKey,
  render,
  emptyMenu = false,
  blackMenu,
  lightColorVariant = false,
  menuIconType
}) => {
  const { currentOffset } = useScroll();
  const { mobileVersion } = useResize();
  const [sections, setSections] = useState<SectionsQueueT | {}>({});
  const [
    scrolledSection,
    setScrolledSection
  ] = useState<MenuItemsIDMenu | null>(null);

  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleSetOpen: handleSetOpenT = value => {
    setOpen(value);
  };

  useEffect(() => {
    handleSetScrollSection(sections, currentOffset, setScrolledSection);
  }, [currentOffset, scrolledSection, sections]);

  useLayoutEffect(() => {
    !emptyMenu && !scrolled && setOpen(!mobileVersion);
  }, [emptyMenu, mobileVersion, scrolled]);

  useLayoutEffect(() => {
    if (!scrolled && currentOffset > 100) {
      setScrolled(true);
      setOpen(false);
    }
  }, [currentOffset, open, scrolled]);

  return (
    <>
      <Helmet helmetKey={helmetKey} />
      <BackgroundWrapper lightColorVariant={lightColorVariant}>
        <FlexBox>
          <SideBar
            scrolledSection={scrolledSection}
            handleSetOpen={handleSetOpen}
            open={open}
            emptyMenu={emptyMenu}
            blackMenu={blackMenu}
            menuIconType={menuIconType}
          />
          <ContentWrapper>{render(open, setSections)}</ContentWrapper>
        </FlexBox>
      </BackgroundWrapper>
    </>
  );
};

export default Layout;
