import React, { FC } from "react";

import { SIDEBAR_WIDTH } from "../partials/SideBar";
import styled from "../styling/styled";
import { rem } from "../styling/theme";

const Wrapper = styled.div<EdgeBlockStyleProps>`
  position: absolute;
  left: ${({ side, sideDistance }): string =>
    side === "left" ? rem(sideDistance) : "auto"};
  right: ${({ side, sideDistance }): string =>
    side === "right" ? rem(sideDistance) : "auto"};
  top: ${({ top }): string => (top !== "auto" ? rem(top) : top)};
  bottom: ${({ bottom }): string => (bottom !== "auto" ? rem(bottom) : bottom)};
  height: ${({ height }): string => height};
  width: ${({ width }): string => width};
  z-index: ${({ layer }): number => layer};
`;

type SideT = "left" | "right";

type EdgeBlockT = {
  top?: number | "auto";
  bottom?: number | "auto";
  height?: string;
  width?: string;
  side: SideT;
  sideDistance?: number;
  layer?: number;
};

type EdgeBlockStyleProps = {
  top: number | "auto";
  bottom: number | "auto";
  side: SideT;
  height: string;
  width: string;
  sideDistance: number;
  layer: number;
};

const EdgeBlock: FC<EdgeBlockT> = ({
  children,
  top = "auto",
  bottom = "auto",
  height = "auto",
  width = "auto",
  sideDistance = -SIDEBAR_WIDTH,
  side,
  layer = -1
}) => {
  return (
    <Wrapper
      sideDistance={sideDistance}
      height={height}
      width={width}
      bottom={bottom}
      side={side}
      top={top}
      layer={layer}
    >
      {children}
    </Wrapper>
  );
};

export default EdgeBlock;
