import React, { FC } from "react";

import styled from "../styling/styled";
import { theme as importedTheme, rem } from "../styling/theme";

export const ARROW_EASING = "cubic-bezier(0.000, 0.550, 0.700, 1);";
export const ARROW_TIME = "0.35s";
export const ARROW_DOUBLE_TIME = "0.7s";

const Wrapper = styled.div<ArrowWidthStylePropsT>`
  width: ${({ width }): string => rem(width)};
  position: relative;
`;

const Line = styled.div<VisibleStylePropsT>`
  position: absolute;
  width: ${({ visible }): string => (visible ? "100%" : "0%")};
  height: 2px;
  background-color: ${({ color }): string => color};
  transition: ${"width " + ARROW_TIME + " " + ARROW_EASING};
  transition-delay: ${({ visible }): string => (visible ? "0s" : ARROW_TIME)};
`;

const Side = styled.div<VisibleStylePropsT & ArrowWidthStylePropsT>`
  position: absolute;
  right: -1px;
  width: ${({ visible, width }): string =>
    visible ? rem(width > 75 ? 20 : 13) : "0px"};
  height: 2px;
  background-color: ${({ color }): string => color};
  transform-origin: right;
  transition: ${"width " + ARROW_TIME + " " + ARROW_EASING};
  transition-delay: ${({ visible }): string => (visible ? ARROW_TIME : "0s")};
`;

const Circle = styled.div<BubbleWidthStylePropsT>`
  position: absolute;
  width: ${({ bubbleWidth }): string => rem(bubbleWidth)};
  height: ${({ bubbleWidth }): string => rem(bubbleWidth)};
  right: ${({ bubbleWidth }): string => rem(-bubbleWidth / 2 + 5)};
  bottom: ${({ bubbleWidth }): string => rem(-bubbleWidth / 2)};
  border-radius: ${({ bubbleWidth }): string => rem(bubbleWidth / 2)};
  border: ${({ theme }): string => "1px solid " + theme.colors.white};
  transform: ${({ visible }): string => (visible ? "scale(1)" : "scale(0)")};
  transition: ${"all " + ARROW_TIME + " " + ARROW_EASING};
  transition-delay: ${({ visible }): string => (visible ? "0s" : ARROW_TIME)};
  opacity: 0.5;
  ${({ theme }): string[] => [theme.media.maxL]} {
    right: ${({ bubbleWidth, width = 0 }): string =>
      rem(-bubbleWidth / 2 + width / 2)};
  }
`;

const Top = styled(Side)`
  transform: rotate(40deg) translateY(0.5px);
`;

const Bottom = styled(Side)`
  transform: rotate(-40deg) translateY(-0.5px);
`;

type VisibleStylePropsT = {
  visible: boolean;
  color: string;
};

type ArrowWidthStylePropsT = {
  width: number;
};

type BubbleWidthStylePropsT = {
  bubbleWidth: number;
  width?: number;
  visible: boolean;
};

type ArrowT = {
  visible: boolean;
  bubbleWidth?: number;
  width: number;
  color?: string;
  className?: string;
};

const Arrow: FC<ArrowT> = ({
  visible,
  bubbleWidth,
  width,
  color = importedTheme.colors.primary,
  className
}) => {
  return (
    <Wrapper width={width} className={className}>
      <Line color={color} visible={visible} />
      <Top color={color} width={width} visible={visible} />
      <Bottom color={color} width={width} visible={visible} />
      {!!bubbleWidth && (
        <Circle width={width} bubbleWidth={bubbleWidth} visible={visible} />
      )}
    </Wrapper>
  );
};

export default Arrow;
