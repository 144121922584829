import React, { FC } from "react";

import Link from "./Link";
import { MenuIconWrapper } from "./Toggle";

type MenuIconT = {
  href: string;
};

const MenuIcon: FC<MenuIconT> = ({ href, children }) => {
  return (
    <MenuIconWrapper>
      <Link route={href}>{children}</Link>
    </MenuIconWrapper>
  );
};

export default MenuIcon;
