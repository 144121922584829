import React, { FC, useRef } from "react";

import styled from "../styling/styled";
import { theme as importedTheme, rem } from "../styling/theme";
import InnerHTML from "./InnerHTML";

const Wrapper = styled.h1`
  display: flex;
  flex-direction: column;
`;

const SymbolWrapper = styled.div<SymolWidthT>`
  position: relative;
  display: flex;
  align-items: center;
  width: ${({ width = 40 }): string => "calc(" + rem(width) + " + 100%)"};
  left: ${({ width = 40 }): string => rem(-width)};
  margin-bottom: ${rem(-30)};
  ${({ theme }): string[] => [theme.media.maxL]} {
    left: ${rem(-35)};
  }
  ${({ theme }): string[] => [theme.media.maxMD]} {
    margin-bottom: ${rem(-20)};
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    left: ${rem(-30)};
  }
`;

const Pre = styled.span`
  position: relative;
  font-size: ${rem(18)};
  letter-spacing: 5px;
  text-transform: uppercase;
  display: inline-block;
  padding-left: ${rem(5)};
  ${({ theme }): string[] => [theme.media.maxL]} {
    font-size: ${rem(16)};
  }
`;

const Title = styled.span`
  font-size: ${rem(50)};
  letter-spacing: 3px;
  line-height: ${rem(55)};
  text-transform: uppercase;
  font-family: "Overpass Mono";
  margin-top: ${rem(10)};
  ${({ theme }): string[] => [theme.media.maxL]} {
    font-size: ${rem(35)};
    line-height: ${rem(45)};
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    font-size: ${rem(30)};
  }
`;

const Symbol = styled.span<SymbolStyleT>`
  position: relative;
  color: ${({ color }): string => color};
  font-size: ${rem(65)};
  font-family: "Overpass Mono";
  margin-bottom: ${rem(8)};
  opacity: ${({ show }): number => (show ? 1 : 0)};
  ${({ theme }): string[] => [theme.media.maxMD]} {
    font-size: ${rem(45)};
  }
`;

type SymbolStyleT = {
  color: string;
  show: boolean;
};

type SymolWidthT = {
  width?: number;
};

type HeadingT = {
  symbol?: string | null;
  title: string;
  pre?: string;
  symbolColor?: string;
};

const Heading: FC<HeadingT> = ({
  symbol = "{",
  pre,
  title,
  children,
  symbolColor = importedTheme.colors.primary
}) => {
  const symbolRef = useRef<HTMLSpanElement>(null);
  const renderedSymbol = symbol ? symbol : "'";

  return (
    <Wrapper>
      {children}
      <SymbolWrapper width={symbolRef.current?.offsetWidth}>
        <Symbol
          ref={symbolRef}
          show={renderedSymbol !== "'"}
          color={symbolColor}
        >
          {renderedSymbol}
        </Symbol>
        {pre ? (
          <Pre>
            <InnerHTML content={pre} />
          </Pre>
        ) : (
          <Title>
            <InnerHTML content={title} />
          </Title>
        )}
      </SymbolWrapper>
      {pre && (
        <Title>
          <InnerHTML content={title} />
        </Title>
      )}
    </Wrapper>
  );
};

export default Heading;
