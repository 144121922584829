import { useIntl } from "gatsby-plugin-intl";
import React, { FC, useEffect, useState } from "react";

import { useResize } from "../hooks/useResize";
import { useScroll } from "../hooks/useScroll";
import styled from "../styling/styled";
import { rem } from "../styling/theme";

const Wrapper = styled.div<VisibleStyleT>`
  display: flex;
  position: absolute;
  bottom: ${rem(50)};
  transform: ${({ visible }): string =>
    visible ? "translateY(0)" : "translateY(-200%)"};
  left: 0;
  opacity: ${({ visible }): number => (visible ? 1 : 0)};
  transition: all 500ms cubic-bezier(0, 0.42, 0.5, 1);
  ${({ theme }): string[] => [theme.media.maxL]} {
    transform: ${({ visible }): string =>
      visible
        ? "translateX(-50%) translateY(280px)"
        : "translateX(-50%) translateY(0)"};
    left: 50%;
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    transform: ${({ visible }): string =>
      visible
        ? "translateX(-50%) translateY(220px)"
        : "translateX(-50%) translateY(0)"};
    left: 30%;
  }
`;

const Text = styled.div`
  font-size: ${rem(12)};
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-left: ${rem(15)};
  display: inline-block;
  animation: bounce 1s infinite alternate;
`;

const Arrow = styled.div`
  height: ${rem(65)};
  width: ${rem(10)};
  position: relative;
  opacity: 0.8;
`;

const Line = styled.div`
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #888888;
`;

const ColoredLine = styled.div`
  position: absolute;
  width: 2px;
  background-color: ${({ theme }): string => theme.colors.primary};
  animation: bounce 1s infinite alternate;
  height: 20px;
`;

const Side = styled.div`
  position: absolute;
  bottom: 0;
  width: 2px;
  height: ${rem(15)};
  background-color: #888888;
  transform-origin: bottom;
`;

const Top = styled(Side)`
  transform: rotate(40deg);
`;

const Bottom = styled(Side)`
  transform: rotate(-40deg);
`;

type ScrollDownT = {
  disable?: boolean;
};

type VisibleStyleT = {
  visible: boolean;
};

const ScrollDown: FC<ScrollDownT> = () => {
  const intl = useIntl();
  const { currentOffset } = useScroll();
  const { mobileVersion } = useResize();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (currentOffset > 300) {
      setVisible(false);
      return;
    }
    const timer = setTimeout(() => {
      setVisible(true);
    }, 1000);

    return (): void => {
      clearTimeout(timer);
    };
  }, [currentOffset, mobileVersion]);

  return (
    <Wrapper visible={visible}>
      <Arrow>
        <Line />
        <ColoredLine />
        <Top />
        <Bottom />
      </Arrow>
      <Text>{intl.formatMessage({ id: "home.intro.scroll" })}</Text>
    </Wrapper>
  );
};

export default ScrollDown;
