import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";
import ReactHelmet from "react-helmet";

import { SuccessStoriesIdsT } from "../partials/SuccessStories/data";

export type HelmetKeys =
  | "home"
  | "successStories"
  | "error"
  | "privacyPolicy"
  | SuccessStoriesIdsT;

type HelmetT = {
  helmetKey: HelmetKeys;
};

const Helmet: FC<HelmetT> = ({ helmetKey }) => {
  const intl = useIntl();

  return (
    <ReactHelmet
      title={intl.formatMessage({ id: `${helmetKey}.meta.meta_title` })}
      meta={[
        {
          name: "description",
          content: intl.formatMessage({ id: `${helmetKey}.meta.meta_desc` })
        },
        {
          property: "og:title",
          content: intl.formatMessage({ id: `${helmetKey}.meta.meta_title` })
        },
        {
          property: "og:description",
          content: intl.formatMessage({ id: `${helmetKey}.meta.meta_desc` })
        },
        {
          property: "og:image",
          content: "https://www.bootiq.io/images/og-image.png"
        }
      ]}
    >
      <script type="text/javascript">
        {`var leady_track_key = "J4XcP56y1TOHTsT0";
          (function() {
            var l = document.createElement("script");
            l.type = "text/javascript";
            l.async = true;
            l.src = "https://t.leady.com/" + leady_track_key + "/L.js";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(l, s);
          })();
        `}
      </script>
    </ReactHelmet>
  );
};

export default Helmet;
