import { SIDEBAR_WIDTH } from "../partials/SideBar";
import styled from "../styling/styled";
import { rem } from "../styling/theme";

export const PADDING_BASE = 40;
const CONTAINER_MAX_WIDTH = 1800;
export const CONTAINER_PADDING = SIDEBAR_WIDTH + PADDING_BASE;

type HeightStyleProps = {
  height?: string;
  layer?: number;
};

const Container = styled.div<HeightStyleProps>`
  width: 100%;
  height: ${({ height = "auto" }): string => height};
  max-width: ${CONTAINER_MAX_WIDTH}px;
  margin: 0 auto;
  padding-left: ${rem(CONTAINER_PADDING)};
  padding-right: ${rem(PADDING_BASE)};
  position: relative;
  z-index: ${({ layer = "auto" }): number | string => layer};

  ${({ theme }): string[] => [theme.media.maxL]} {
    padding-left: ${rem(PADDING_BASE)};
    padding-right: ${rem(PADDING_BASE)};
  }

  ${({ theme }): string[] => [theme.media.maxS]} {
    padding-left: ${rem(PADDING_BASE / 1.5)};
    padding-right: ${rem(PADDING_BASE / 1.5)};
  }
`;

export const LeftOpositeMargin = styled.div<HeightStyleProps>`
  position: relative;
  z-index: -1;
  margin-left: ${rem(-CONTAINER_PADDING)};

  ${({ theme }): string[] => [theme.media.maxL]} {
    margin-left: ${rem(-PADDING_BASE)};
  }

  ${({ theme }): string[] => [theme.media.maxS]} {
    margin-left: ${rem(-PADDING_BASE / 1.5)};
  }
`;

export default Container;
