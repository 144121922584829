import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { SIDEBAR_EASING } from "../partials/SideBar";
import styled from "../styling/styled";
import { rem, theme } from "../styling/theme";
import { handleSetOpenT } from "./Layout";

export const MenuIconWrapper = styled.div<MenuIconStyleT>`
  display: block;
  position: absolute;
  top: ${rem(55)};
  height: ${rem(60)};
  right: ${({ open }): string => (open ? "25%" : "50%")};
  cursor: pointer;
  transform: translateX(50%);
  transition: ${({ transition }): string => "right 500ms " + transition};
  z-index: 10;

  ${({ theme }): string[] => [theme.media.maxL]} {
    position: fixed;
    right: ${rem(20)};
    top: ${rem(30)};
    height: ${rem(65)};
    transform: translateX(0);
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0 ${rem(10)};
  }
`;

const Status = styled.span<MenuIconStyleT>`
  text-transform: uppercase;
  font-size: ${rem(12)};
  display: inline-block;
  margin-top: ${rem(10)};
  position: absolute;
  color: rgba(255, 255, 255, 0.6);
  transition: ${({ transition }): string => "opacity 300ms " + transition};
`;

const StatusOpen = styled(Status)<MenuIconStyleT>`
  opacity: ${({ open }): number => (open ? 1 : 0)};
`;

const StatusClose = styled(Status)<MenuIconStyleT>`
  opacity: ${({ open }): number => (open ? 0 : 1)};
`;

const Burger = styled.div`
  position: relative;
  width: ${rem(35)};
  height: ${rem(35)};
  display: flex;
  justify-content: center;
`;

const Line = styled.div<MenuIconStyleT>`
  position: absolute;
  height: 1px;
  display: block;
  background: ${theme.colors.white};
  transition: ${({ transition }): string => "all 300ms " + transition};
`;

const TopLine = styled(Line)<MenuIconStyleT>`
  width: 100%;
  transform: ${({ open }): string => (open ? "rotate(45deg)" : "0")};
  bottom: ${rem(10)};
`;

const BottomLine = styled(Line)<MenuIconStyleT>`
  width: ${({ open }): string => (open ? "100%" : "80%")};
  left: 0;
  transform: ${({ open }): string => (open ? "rotate(-45deg)" : "0")};
  bottom: ${({ open }): string => (open ? rem(10) : "0")};
`;

type MenuIconStyleT = {
  open?: boolean;
  transition?: string;
};

type ToggleT = {
  open: boolean;
  handleSetOpen: handleSetOpenT;
};

const Toggle: FC<ToggleT> = ({ open, handleSetOpen }) => {
  const intl = useIntl();

  return (
    <MenuIconWrapper
      open={open}
      onClick={(): void => {
        handleSetOpen(!open);
      }}
      transition={SIDEBAR_EASING}
    >
      <Burger>
        <TopLine open={open} transition={SIDEBAR_EASING} />
        <BottomLine open={open} transition={SIDEBAR_EASING} />
      </Burger>
      <StatusOpen open={open} transition={SIDEBAR_EASING}>
        {intl.formatMessage({ id: "sideBar.statusOpen" })}
      </StatusOpen>
      <StatusClose open={open} transition={SIDEBAR_EASING}>
        {intl.formatMessage({ id: "sideBar.statusClose" })}
      </StatusClose>
    </MenuIconWrapper>
  );
};

export default Toggle;
