import { Link } from "gatsby-plugin-intl";
import React, { FC, ReactNode, useEffect, useState } from "react";

import styled from "../styling/styled";
import { theme as importedTheme, rem } from "../styling/theme";
import Arrow, { ARROW_EASING, ARROW_TIME } from "./Arrow";
import { JustifyContentT } from "./FlexBox";

const PositionWrapper = styled.div<PositionStyleT>`
  display: flex;
  justify-content: ${({ justifyContent }): string => justifyContent};
`;

const WrapperStyles = `
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${rem(45)};
  padding: 0;
  background-color: transparent;
  border: none;
  z-index: 2;
  cursor: pointer;
`;

const ButtonWrapper = styled.button`
  ${WrapperStyles};
  font-family: "JetBrains Mono", Arial, sans-serif;
  cursor: pointer;
`;

const LinkWrapper = styled(Link)`
  ${WrapperStyles};
  text-decoration: none;
`;

const ExternalWrapper = styled.a`
  ${WrapperStyles};
  text-decoration: none;
`;

const ModalWrapper = styled.div`
  ${WrapperStyles};
`;

const Text = styled.span<ButtonStylePropsT>`
  position: relative;
  color: ${({ hover, theme, disabled, variant }): string =>
    disabled
      ? theme.colors.gray
      : hover || variant === "light"
      ? theme.colors.black
      : theme.colors.white};
  left: ${({ hover }): string => (hover ? "13px" : "3px")};
  font-size: ${rem(14)};
  font-weight: 700;
  padding: 0 ${rem(10)};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: block;
  transition: ${"all " + ARROW_TIME + " " + ARROW_EASING};
  transition-delay: 0;
  margin-right: ${rem(30)};
`;

const Shape = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

const VisibleShape = styled(Shape)<ButtonStylePropsT>`
  width: ${({ hover }): string => (hover ? "100%" : "40%")};
  border: 1px solid;
  border-color: ${({ hover, theme }): string =>
    hover ? theme.colors.primary : theme.colors.lightGray};
  transition: ${"all " + ARROW_TIME + " " + ARROW_EASING};
  background: ${({ hover, theme }): string =>
    hover ? theme.colors.primary : "transparent"};
  opacity: ${({ hover }): number => (hover ? 1 : 0.7)};
`;

const ShapeImitation = styled(Shape)`
  width: 100%;
  z-index: 1;
`;

const LineWrapper = styled.div`
  width: ${rem(15)};
  position: relative;
  top: -1px;
`;

const Line = styled.div<ButtonStylePropsT>`
  position: absolute;
  width: ${({ hover }): string => (hover ? "100%" : "0%")};
  height: 2px;
  background-color: ${({ theme }): string => theme.colors.black};
  transition: ${"width " + ARROW_TIME};
  transition-delay: 0.13s;
  opacity: ${({ hover }): number => (hover ? 1 : 0)};
`;

const StyledArrow = styled(Arrow)`
  position: absolute;
  right: ${rem(-20)};
  height: 100%;
  display: flex;
  align-items: center;
`;

type PositionStyleT = {
  justifyContent: JustifyContentT;
};

type ButtonVariantT = "primary" | "light";

type ButtonStylePropsT = {
  hover: boolean;
  disabled?: boolean;
  variant?: ButtonVariantT;
};

type ButtonT = {
  text: string;
  disabled?: boolean;
  variant?: ButtonVariantT;
  route?: string;
  externalRoute?: string;
  justifyContent?: JustifyContentT;
  manualHover?: boolean;
  modal?: () => void;
};

const Button: FC<ButtonT> = ({
  disabled,
  text,
  variant = "primary",
  route,
  externalRoute,
  justifyContent = "flex-start",
  manualHover = false,
  modal
}) => {
  const [hover, setHover] = useState(false);

  useEffect(() => {
    setHover(manualHover);
  }, [manualHover]);

  type disableHandleT = (state: boolean) => void;
  const disableHandle: disableHandleT = state => {
    if (!disabled) {
      setHover(state);
    }
    return;
  };

  const renderContent = (): ReactNode => (
    <>
      <VisibleShape disabled={disabled} hover={hover} />
      <ShapeImitation>
        <LineWrapper>
          <Line hover={hover} />
        </LineWrapper>
      </ShapeImitation>
      <Text disabled={disabled} hover={hover} variant={variant}>
        {text}
      </Text>
      <StyledArrow
        visible={true}
        width={35}
        color={
          variant === "light"
            ? importedTheme.colors.black
            : importedTheme.colors.primary
        }
      />
    </>
  );

  return (
    <PositionWrapper
      justifyContent={justifyContent}
      onMouseEnter={(): void => disableHandle(true)}
      onMouseLeave={(): void => setHover(false)}
    >
      {modal ? (
        <ModalWrapper onClick={modal}>{renderContent()}</ModalWrapper>
      ) : !route && !externalRoute ? (
        <ButtonWrapper type="submit" disabled={disabled}>
          {renderContent()}
        </ButtonWrapper>
      ) : externalRoute ? (
        <ExternalWrapper target="_blank" href={externalRoute}>
          {renderContent()}
        </ExternalWrapper>
      ) : (
        <LinkWrapper to={route || "/"}>{renderContent()}</LinkWrapper>
      )}
    </PositionWrapper>
  );
};

export default Button;
