import _ from "lodash";
import { useEffect, useState } from "react";

import { MOBILE_VERSION_BREAKPOINT } from "../styling/theme";

type useResizeT = {
  mobileVersion: boolean;
  windowWidth: number;
  windowHeight: number;
};

export const useResize = (): useResizeT => {
  const WINDOW_CONST = typeof window !== "undefined" && window;

  const [mobileVersion, setMobileVersion] = useState(false);
  const [dimensions, setDimensions] = useState({
    windowWidth: 0,
    windowHeight: 0
  });

  const { windowWidth, windowHeight } = dimensions;

  useEffect(() => {
    const handleResize = (): void => {
      setDimensions({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      });

      if (window && window.innerWidth < MOBILE_VERSION_BREAKPOINT) {
        setMobileVersion(true);
        return;
      }

      setMobileVersion(false);
    };
    handleResize();

    WINDOW_CONST &&
      WINDOW_CONST.addEventListener("resize", _.throttle(handleResize, 300));
    return (): void => {
      WINDOW_CONST &&
        WINDOW_CONST.removeEventListener(
          "resize",
          _.throttle(handleResize, 300)
        );
    };
  }, [WINDOW_CONST]);

  return {
    mobileVersion,
    windowWidth,
    windowHeight
  };
};
