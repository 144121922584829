import React, { FC } from "react";

import Container from "../../components/Container";
import InTouch from "./InTouch";

const SuccessStoriesFooter: FC = () => {
  return (
    <Container layer={0}>
      <InTouch lightVariant />
    </Container>
  );
};

export default React.memo(SuccessStoriesFooter);
