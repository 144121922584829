import axios from "axios";
import { useIntl } from "gatsby-plugin-intl";
import React, { FC, useState } from "react";
import { FieldValues, OnSubmit, useForm } from "react-hook-form";

import Button from "../components/Button";
import styled from "../styling/styled";
import { rem } from "../styling/theme";

const StyledInput = styled.input`
  color: ${({ theme }): string => theme.colors.lightGray};
  font-size: ${rem(14)};
  letter-spacing: 0.5px;
  line-height: ${rem(20)};
  background-color: transparent;
  padding: ${rem(12)} ${rem(15)};
  width: ${rem(230)};
  margin-right: ${rem(30)};
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0;
  border-right-width: 0;
  border-style: solid;
  border-color: ${({ theme }): string => theme.colors.lightGray};
  font-family: "JetBrains Mono", Arial, sans-serif;
  transition: border-color 0.35s ease-out;
  ${({ theme }): string[] => [theme.media.maxMD]} {
    width: ${rem(300)};
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    margin-bottom: ${rem(30)};
    margin-right: 0;
    width: 85vw;
  }
  &:focus,
  &:hover {
    border-color: ${({ theme }): string => theme.colors.primary};
  }
`;

const StyledForm = styled.form`
  position: relative;
`;

const Errors = styled.div`
  position: absolute;
  width: 100%;
  ${({ theme }): string[] => [theme.media.maxM]} {
    position: relative;
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }): string[] => [theme.media.maxM]} {
    flex-direction: column;
  }
`;

type StatusSererT = {
  result: boolean;
  message: string | null;
};

type ServerStateT = {
  status?: StatusSererT;
  submitting: boolean;
};

const Form: FC = () => {
  const intl = useIntl();
  const { register, handleSubmit, errors } = useForm();
  const errorExist = Object.keys(errors).length !== 0;
  const [serverState, setServerState] = useState<ServerStateT>({
    status: {
      result: false,
      message: null
    },
    submitting: false
  });

  type handleServerResponseT = (
    { result, message }: StatusSererT,
    form: HTMLFormElement
  ) => void;
  const handleServerResponse: handleServerResponseT = (
    { result, message },
    form
  ) => {
    setServerState({
      submitting: false,
      status: { result, message }
    });
    if (result) {
      form.reset();
    }
  };

  const formSubmit: OnSubmit<FieldValues> = async (data, e) => {
    e && e.preventDefault();
    const form = e?.target;

    setServerState({ submitting: true });

    axios({
      method: "post",
      url: "https://getform.io/f/d9169e2b-a49e-4dbf-aa78-5c1a64d02c8e",
      data: new FormData(form as HTMLFormElement)
    })
      .then(r => {
        handleServerResponse(
          {
            result: true,
            message: intl.formatMessage({
              id: "home.contact.success"
            })
          },
          form as HTMLFormElement
        );
      })
      .catch(r => {
        handleServerResponse(
          {
            result: false,
            message: intl.formatMessage({
              id: "home.contact.errorFail"
            })
          },
          form as HTMLFormElement
        );
      });
  };
  return (
    <StyledForm onSubmit={handleSubmit(formSubmit)} noValidate>
      <InputsWrapper>
        <div className="form-group">
          <StyledInput
            ref={register({
              required: intl.formatMessage({
                id: "home.contact.errorRequired"
              })
            })}
            type="text"
            name="email"
            placeholder={intl.formatMessage({
              id: "home.contact.placeholder"
            })}
          />
        </div>

        <Button
          disabled={serverState.submitting || errorExist}
          text={intl.formatMessage({ id: "home.contact.submit" })}
        />
      </InputsWrapper>
      <Errors>
        {errors &&
          Object.values(errors).map((item, index) => (
            <p key={index}>{item.message}</p>
          ))}
        {serverState.status && <p>{serverState.status.message}</p>}
      </Errors>
    </StyledForm>
  );
};

export default React.memo(Form);
