import React, { FC, ReactNode } from "react";

import styled from "../styling/styled";
import { theme as importedTheme, rem } from "../styling/theme";

const Wrapper = styled.p<ParagraphStyleProps>`
  color: ${({ color }): string => color};
  font-weight: ${({ bold }): number => (bold ? 700 : 400)};
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  max-width: ${({ maxWidth }): string => (maxWidth ? rem(maxWidth) : "none")};
  ${({ theme }): string[] => [theme.media.maxM]} {
    font-size: ${rem(16)};
  }
`;

type ParagraphStyleProps = {
  maxWidth?: number;
  color: string;
  bold: boolean;
};

type ParahraphT = {
  paragraph: string | ReactNode;
  maxWidth?: number;
  color?: string;
  bold?: boolean;
  className?: string;
};

const Paragraph: FC<ParahraphT> = ({
  paragraph,
  maxWidth,
  color = importedTheme.colors.lightGray,
  bold = false,
  className
}) => {
  return (
    <Wrapper
      className={className}
      bold={bold}
      color={color}
      maxWidth={maxWidth}
    >
      {paragraph}
    </Wrapper>
  );
};

export default Paragraph;
