import React, { FC } from "react";

import styled from "../styling/styled";

export type AlignItemsT = "flex-start" | "flex-end" | "center" | "stretch";
export type JustifyContentT =
  | "space-between"
  | "flex-start"
  | "flex-end"
  | "center";
export type FlexDirectionT = "column" | "row";

type FlexBoxStyleProps = {
  alignItems: AlignItemsT;
  justifyContent: JustifyContentT;
  flexDirection: FlexDirectionT;
};

type FlexBoxT = {
  alignItems?: AlignItemsT;
  justifyContent?: JustifyContentT;
  flexDirection?: FlexDirectionT;
  className?: string;
};

const FlexBoxWrapper = styled.div<FlexBoxStyleProps>`
  display: flex;
  justify-content: ${({ justifyContent }): JustifyContentT => justifyContent};
  align-items: ${({ alignItems }): AlignItemsT => alignItems};
  flex-direction: ${({ flexDirection }): FlexDirectionT => flexDirection};
`;

const FlexBox: FC<FlexBoxT> = ({
  children,
  justifyContent = "flex-start",
  alignItems = "flex-start",
  flexDirection = "row",
  className
}) => {
  return (
    <FlexBoxWrapper
      flexDirection={flexDirection}
      alignItems={alignItems}
      justifyContent={justifyContent}
      className={className}
    >
      {children}
    </FlexBoxWrapper>
  );
};

export default FlexBox;
