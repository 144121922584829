import { useIntl } from "gatsby-plugin-intl";
import React, { FC, ReactNode } from "react";

import {
  IconBack,
  IconHome,
  LogoShortSVG,
  LogoVerticalSVG
} from "../assets/images/Images";
import { handleSetOpenT } from "../components/Layout";
import Link from "../components/Link";
import Menu, { MenuItemsIDMenu } from "../components/Menu";
import MenuIcon from "../components/MenuIcon";
import Toggle from "../components/Toggle";
import { useResize } from "../hooks/useResize";
import { useScroll } from "../hooks/useScroll";
import styled from "../styling/styled";
import { theme as importedTheme, rem } from "../styling/theme";

export const SIDEBAR_EASING = "cubic-bezier(0.515, 0.005, 0.500, 1.000)";
export const SIDEBAR_WIDTH = 120;
export const OPEN_SIDEBAR_WIDTH = SIDEBAR_WIDTH * 2;
const RIBBON_HEIGHT = 25;

const SideBarWrapper = styled.div<ToggleStatusT>`
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ open }): string =>
    rem(open ? OPEN_SIDEBAR_WIDTH : SIDEBAR_WIDTH)};
  transition: ${"width 500ms " + SIDEBAR_EASING};
  transform: ${({ open }): string =>
    open ? "translateX(0)" : "translateX(-2px)"};
  z-index: 100;
  ${({ theme }): string[] => [theme.media.maxLg]} {
    background-color: ${({ theme }): string => theme.colors.black};
  }
  ${({ theme }): string[] => [theme.media.maxL]} {
    width: ${({ open }): string => rem(open ? OPEN_SIDEBAR_WIDTH * 1.3 : 0)};
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    width: ${({ open }): string => (open ? "80%" : "0%")};
  }
`;

const Side = styled.div<ColorT>`
  display: flex;
  width: 100%;
  border-right: ${({ color }): string => "1px solid " + color};
  transition: ${"margin 300ms " +
    SIDEBAR_EASING +
    ", border-color 400ms " +
    SIDEBAR_EASING};
`;

const Top = styled(Side)`
  flex-grow: 0.3;
`;

const Center = styled(Side)<ToggleStatusT>`
  flex-grow: 1;
  margin-bottom: ${({ open }): string => (open ? "0" : rem(RIBBON_HEIGHT / 2))};
  ${({ theme }): string[] => [theme.media.maxL]} {
    margin-bottom: 0;
  }
`;

const Bottom = styled(Side)<ToggleStatusT>`
  position: relative;
  height: ${rem(SIDEBAR_WIDTH)};
  justify-content: center;
  align-items: center;
  margin-top: ${({ open }): string => (open ? "0" : rem(RIBBON_HEIGHT / 2))};
  ${({ theme }): string[] => [theme.media.maxL]} {
    justify-content: flex-start;
    margin-top: 0;
  }
`;

const Content = styled.div<ToggleStatusT>`
  position: relative;
  min-width: ${rem(OPEN_SIDEBAR_WIDTH)};
  height: 100%;
  padding-top: 230px;
  padding-left: 50px;
  left: ${({ open }): string => (open ? "0" : rem(-OPEN_SIDEBAR_WIDTH))};
  transition: ${"left 500ms " + SIDEBAR_EASING};
  ${({ theme }): string[] => [theme.media.maxL]} {
    left: ${({ open }): string => (open ? "0" : rem(-OPEN_SIDEBAR_WIDTH * 2))};
  }
`;

const ContentCenter = styled(Content)`
  padding-top: 0;
`;

const ContentBottom = styled(ContentCenter)`
  color: ${({ theme }): string => theme.colors.gray};
  font-size: ${rem(12)};
`;

const VerticalLogo = styled(LogoVerticalSVG)<ToggleStatusT>`
  position: absolute;
  height: ${rem(180)};
  left: 0;
  top: ${({ open }): string => (open ? "0" : rem(-180))};
  transition: ${"top 750ms " + SIDEBAR_EASING};
`;

const ShortLogo = styled(LogoShortSVG)<ToggleStatusT & ColorT>`
  position: absolute;
  width: 57px;
  bottom: ${({ open }): string => (open ? "-200px" : rem(SIDEBAR_WIDTH / 2))};
  transform: translateY(50%);
  transition: ${"bottom 700ms " + SIDEBAR_EASING};
  ${({ theme }): string[] => [theme.media.maxL]} {
    display: none;
  }
  path {
    fill: ${({ color }): string => color};
    transition: ${"fill 400ms " + SIDEBAR_EASING};
  }
`;

const CenteredShortLogo = styled(LogoShortSVG)`
  position: absolute;
  width: ${rem(60)};
  right: 50%;
  top: ${rem(55)};
  transform: translateX(50%);
  path {
    fill: ${({ theme }): string => theme.colors.primary};
  }
`;

const Home = styled(IconHome)<ColorT>`
  position: relative;
  width: ${rem(25)};
  top: ${rem(18)};
  path {
    fill: ${({ color }): string => color};
  }
`;

const Back = styled(IconBack)<ColorT>`
  position: relative;
  width: ${rem(25)};
  top: ${rem(25)};
  path {
    fill: ${({ color }): string => color};
  }
`;

const Ribbon = styled.div<ToggleStatusT & ColorT>`
  display: block;
  position: absolute;
  width: 100%;
  bottom: ${rem(SIDEBAR_WIDTH)};
  height: ${rem(RIBBON_HEIGHT)};
  left: ${({ open }): string => (open ? rem(-OPEN_SIDEBAR_WIDTH) : "0")};
  transition: ${"left 600ms " +
    SIDEBAR_EASING +
    ", border-color 400ms " +
    SIDEBAR_EASING};
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${({ color }): string => color};
  ${({ theme }): string[] => [theme.media.maxL]} {
    display: none;
  }
`;

const Links = styled.div`
  text-transform: uppercase;
  margin-top: 5px;
`;

// const Devider = styled.span`
//   display: inline-block;
//   margin: 0 7px;
// `;

export type MenuIconType = "toggle" | string;

type SideBarT = {
  handleSetOpen: handleSetOpenT;
  scrolledSection: MenuItemsIDMenu | null;
  emptyMenu?: boolean;
  blackMenu?: boolean;
  menuIconType?: MenuIconType;
};

export type ToggleStatusT = {
  open: boolean;
};

type ColorT = {
  color: string;
};

const SideBar: FC<SideBarT & ToggleStatusT> = ({
  open,
  handleSetOpen,
  scrolledSection,
  emptyMenu,
  blackMenu,
  menuIconType = "toggle"
}) => {
  const intl = useIntl();
  const { mobileVersion, windowHeight } = useResize();
  const { currentOffset } = useScroll();
  const currentYear = new Date().getFullYear();
  const legal = `© ${currentYear} ${intl.formatMessage({
    id: "sideBar.legal"
  })}`;

  const darkMenuVariant = blackMenu && currentOffset > windowHeight / 2;
  const borderColor = darkMenuVariant
    ? "rgba(153,153,153,0.2)"
    : "rgba(255,255,255,0.2)";
  const logoColor = darkMenuVariant
    ? importedTheme.colors.black
    : importedTheme.colors.primary;
  const iconColor = darkMenuVariant
    ? importedTheme.colors.black
    : importedTheme.colors.white;

  const renderMenuIcon = (iconType: MenuIconType): ReactNode => {
    switch (iconType) {
      case "toggle":
        return <Toggle open={open} handleSetOpen={handleSetOpen} />;
      case "/":
        return (
          <MenuIcon href={iconType}>
            <Home color={iconColor} alt={"Home icon"} />
          </MenuIcon>
        );
      default:
        return (
          <MenuIcon href={iconType}>
            <Back color={iconColor} alt={"Back icon"} />
          </MenuIcon>
        );
    }
  };

  return (
    <>
      {mobileVersion && renderMenuIcon(menuIconType)}
      {mobileVersion && <CenteredShortLogo alt={"Logo"} />}
      <SideBarWrapper open={open}>
        {!mobileVersion && renderMenuIcon(menuIconType)}
        <VerticalLogo open={open} />
        <Top color={borderColor}>
          <Content color={borderColor} open={open}>
            {/* <LanguageSwitcher /> */}
          </Content>
        </Top>
        <Center color={borderColor} open={open}>
          <ContentCenter open={open}>
            {!emptyMenu && (
              <Menu
                handleSetOpen={handleSetOpen}
                scrolledSection={scrolledSection}
              />
            )}
          </ContentCenter>
        </Center>
        <Ribbon color={borderColor} open={open} />
        <Bottom color={borderColor} open={open}>
          <ShortLogo open={open} color={logoColor} alt={"Logo"} />
          <ContentBottom open={open}>
            <span>{legal}</span>
            <Links>
              <Link underline="underline" route="/privacy-policy">
                {intl.formatMessage({ id: "sideBar.privacy" })}
              </Link>
              {/*
                <Devider>|</Devider>
                <Link underline="underline" route="/cookies">
                  {intl.formatMessage({ id: "sideBar.cookies" })}
                </Link>
              */}
            </Links>
          </ContentBottom>
        </Bottom>
      </SideBarWrapper>
    </>
  );
};

export default SideBar;
