import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import EdgeBlock from "../../components/EdgeBlock";
import FlexBox from "../../components/FlexBox";
import Heading from "../../components/Heading";
import { useResize } from "../../hooks/useResize";
import styled from "../../styling/styled";
import { breakpoints, theme as importedTheme, rem } from "../../styling/theme";
import Form from "../Form";

export const SHAPE_HEIGHT = 320;
const FORM_MARGIN = 85;

const Wrapper = styled.div`
  display: flex;
  padding: ${rem(120)} 0;
  ${({ theme }): string[] => [theme.media.maxMD]} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ContactHeadingWrapper = styled.div`
  width: 50%;
  display: flex;
  position: relative;
  ${({ theme }): string[] => [theme.media.maxM]} {
    width: 100%;
  }
`;

const Symbol = styled.span`
  font-size: ${rem(70)};
  font-family: "Overpass Mono";
  text-transform: uppercase;
  color: ${({ theme }): string => theme.colors.primary};
  margin-right: ${rem(50)};
  margin-left: ${rem(20)};
`;

const FormHeading = styled.h3`
  font-size: ${rem(18)};
  letter-spacing: 5px;
  margin-bottom: ${rem(30)};
  text-transform: uppercase;
  ${({ theme }): string[] => [theme.media.maxM]} {
    font-size: ${rem(16)};
    letter-spacing: 4px;
  }
`;

const Shape = styled.div<ShapeStylePropsT>`
  width: ${({ width }): string => rem(width)};
  height: ${({ height }): string => rem(height)};
  background-color: ${({ theme }): string => theme.colors.black};
`;

const FormSectionWrapper = styled.div`
  color: ${({ theme }): string => theme.colors.white};
  display: flex;
  align-items: center;
  ${({ theme }): string[] => [theme.media.maxMD]} {
    flex-direction: column;
    margin-bottom: ${rem(FORM_MARGIN)};
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    margin-bottom: 0;
  }
`;

type InTouchT = {
  lightVariant?: boolean;
};

type ShapeStylePropsT = {
  width: number;
  height: number;
};

const InTouch: FC<InTouchT> = ({ lightVariant }) => {
  const intl = useIntl();
  const { mobileVersion, windowWidth } = useResize();
  const breakpoint = windowWidth < breakpoints.md;

  return (
    <Wrapper>
      <ContactHeadingWrapper>
        <Heading
          symbol="!"
          symbolColor={
            lightVariant
              ? importedTheme.colors.darkText
              : importedTheme.colors.primary
          }
          pre={intl.formatMessage({ id: "home.contact.pre" })}
          title={intl.formatMessage({ id: "home.contact.title" })}
        />
      </ContactHeadingWrapper>
      <FormSectionWrapper>
        <Symbol>{"}"}</Symbol>
        <FlexBox
          alignItems={mobileVersion ? "center" : "flex-start"}
          flexDirection="column"
        >
          <FormHeading>
            {intl.formatMessage({ id: "home.contact.subTitle" })}
          </FormHeading>
          <Form />
        </FlexBox>
      </FormSectionWrapper>
      <EdgeBlock side="right" sideDistance={0} bottom={0}>
        <Shape
          height={breakpoint ? SHAPE_HEIGHT * 0.9 + FORM_MARGIN : SHAPE_HEIGHT}
          width={breakpoint ? windowWidth : windowWidth * 0.45}
        />
      </EdgeBlock>
    </Wrapper>
  );
};

export default React.memo(InTouch);
