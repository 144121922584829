import styled from "../styling/styled";
import { pixelize } from "../styling/theme";

type SpacerSizeT =
  | "extraSmall"
  | "small"
  | "medium"
  | "large"
  | "extraLarge"
  | "huge"
  | "none";

type SpacerStyleProps = {
  size?: SpacerSizeT;
  mobileSize?: SpacerSizeT;
};

type getSpacerSizeT = (size: SpacerSizeT) => number;
const getSpacerSize: getSpacerSizeT = size => {
  switch (size) {
    case "extraSmall":
      return 10;
    case "small":
      return 20;
    case "medium":
      return 50;
    case "large":
      return 80;
    case "extraLarge":
      return 120;
    case "huge":
      return 250;
    case "none":
      return 0;
  }
};

const Spacer = styled.div<SpacerStyleProps>`
  padding-top: ${({ size = "none" }): string => pixelize(getSpacerSize(size))};
  width: 100%;
  ${({ theme }): string[] => [theme.media.maxL]} {
    padding-top: ${({ mobileSize, size = "small" }): string =>
      pixelize(getSpacerSize(mobileSize || size))};
  }
`;

export default Spacer;
