import { IntlShape, useIntl } from "gatsby-plugin-intl";
import scrollTo from "gatsby-plugin-smoothscroll";
import React, { FC, useState } from "react";

import { useResize } from "../hooks/useResize";
import styled from "../styling/styled";
import { rem } from "../styling/theme";
import { BracketItem } from "./BracketItem";
import HoverText from "./HoverText";
import { handleSetOpenT } from "./Layout";
import Link from "./Link";

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0;
`;

const MenuItem = styled.li<HoverStylePropsT>`
  font-size: ${rem(14)};
  line-height: ${rem(45)};
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  ${({ theme }): string[] => [theme.media.maxLg]} {
    font-size: ${rem(16)};
    line-height: ${rem(55)};
  }
  ${({ theme }): string[] => [theme.media.maxL]} {
    font-size: ${rem(20)};
    line-height: ${rem(60)};
  }
`;

const AnchorWrapper = styled.span`
  display: inline-block;
`;

const Anchor = styled(Link)`
  color: ${({ theme }): string => theme.colors.white};
  text-decoration: none;
  white-space: nowrap;
`;

export type MenuItemsIDMenu = "capabalities" | "stories" | "about" | "contact";
type MenuItemT = {
  id: MenuItemsIDMenu;
  item: string;
  href?: string;
  hook?: string;
};
type MenuItemsT = MenuItemT[];

type getMenuItemsT = (intl?: IntlShape) => MenuItemsT;
const getMenuItems: getMenuItemsT = intl => {
  return [
    {
      id: "capabalities",
      item: "Capabilities",
      hook: "#capabalities"
    },
    {
      id: "stories",
      item: "Success stories",
      hook: "#stories"
    },
    {
      id: "about",
      item: "About us",
      hook: "#about"
    },
    {
      id: "contact",
      item: "Get in touch",
      hook: "#contact"
    }
  ];
};

type getSectionHookT = (itemID: MenuItemsIDMenu) => string;
export const getSectionHook: getSectionHookT = itemID => {
  const item = getMenuItems().find(item => item.id === itemID);
  return item?.id || "";
};

type HoverStylePropsT = {
  hover: boolean;
};

type MenuT = FC<{
  scrolledSection: MenuItemsIDMenu | null;
  handleSetOpen: handleSetOpenT;
}>;

const Menu: MenuT = ({ scrolledSection, handleSetOpen }) => {
  const intl = useIntl();
  const { mobileVersion } = useResize();
  const [hover, setHover] = useState<MenuItemsIDMenu | false>(false);

  type handleScrolToT = (hook: string) => void;
  const handleScrolTo: handleScrolToT = hook => {
    scrollTo(hook);
    if (mobileVersion) {
      handleSetOpen(false);
    }
  };

  return (
    <Wrapper>
      {getMenuItems(intl).map((item, index) => {
        const active = scrolledSection === item.id || hover === item.id;

        return (
          <MenuItem
            hover={hover === item.id || scrolledSection === item.id}
            key={index}
            onMouseEnter={(): void => setHover(item.id)}
            onMouseLeave={(): void => setHover(false)}
            onClick={(): void | boolean =>
              item.hook ? handleScrolTo(item.hook) : false
            }
          >
            {item.href ? (
              <AnchorWrapper>
                <BracketItem active={active}>
                  <Anchor route={item.href && item.href}>{item.item}</Anchor>
                </BracketItem>
              </AnchorWrapper>
            ) : (
              <HoverText manualHover={active}>
                <BracketItem active={active}>{item.item}</BracketItem>
              </HoverText>
            )}
          </MenuItem>
        );
      })}
    </Wrapper>
  );
};

export default Menu;
