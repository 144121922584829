import React, { FC, useLayoutEffect, useState } from "react";

import { TextureTemplate, TextureTemplateWhite } from "../assets/images/Images";
import styled from "../styling/styled";
import { rem } from "../styling/theme";

const StyledTexture = styled.div<TextureStyleT>`
  display: block;
  width: ${({ width }): string => width};
  height: ${({ height }): string => height};
  background: ${({ background }): string => background};
`;

type getTextureT = (whiteVariant: boolean) => string;
const getTexture: getTextureT = whiteVariant => {
  if (whiteVariant) {
    return TextureTemplateWhite;
  }

  return TextureTemplate;
};

type getBackgroundT = (
  gradient: string | null,
  color: string | null,
  lightVariant?: boolean
) => string;
const getBackground: getBackgroundT = (
  gradient,
  color,
  whiteVariant = false
) => {
  if (gradient && typeof gradient === "string") {
    return `linear-gradient(180deg, ${gradient} 0%, ${
      whiteVariant ? "rgba(255, 255, 255, 0)" : "rgba(0, 0, 0, 0)"
    } 30%), url(${getTexture(whiteVariant)})`;
  }

  if (color && typeof color === "string") {
    return color;
  }

  return `url(${getTexture(whiteVariant)})`;
};

type TextureStyleT = {
  width: string;
  height: string;
  background: string;
};

type TextureT = {
  width?: string;
  height?: string;
  withGradient?: string;
  withColor?: string;
  lightVariant?: boolean;
};

const Texture: FC<TextureT> = ({
  width = rem(100),
  height = rem(100),
  withGradient = null,
  withColor = null,
  lightVariant
}) => {
  const [background, setBackground] = useState<string>("");

  useLayoutEffect(() => {
    setBackground(getBackground(withGradient, withColor, lightVariant));
  }, [withGradient, withColor, lightVariant]);

  return (
    <StyledTexture width={width} height={height} background={background} />
  );
};

export default Texture;
