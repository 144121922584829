import React, { FC } from "react";

import { SIDEBAR_EASING, SIDEBAR_WIDTH } from "../partials/SideBar";
import styled from "../styling/styled";
import { rem } from "../styling/theme";

const Wrapper = styled.div<MovingBlockStyleProps>`
  position: absolute;
  left: ${({ open }): string => (open ? rem(SIDEBAR_WIDTH) : rem(0))};
  transition: ${({ transition }): string => "left 500ms " + transition};
  z-index: ${({ layer }): number => layer};
  top: ${({ top }): string => (top ? rem(top) : rem(0))};
`;

type MovingBlockT = {
  open: boolean;
  top?: number;
  layer?: number;
};

type MovingBlockStyleProps = {
  open: boolean;
  transition: string;
  top: number;
  layer: number;
};

const MovingBlock: FC<MovingBlockT> = ({
  open,
  children,
  top = 0,
  layer = -1
}) => {
  return (
    <Wrapper layer={layer} open={open} transition={SIDEBAR_EASING} top={top}>
      {children}
    </Wrapper>
  );
};

export default MovingBlock;
