import { MenuItemsIDMenu } from "../components/Menu";
import { SectionsQueueT } from "../hooks/useFillSections";

export type setScrolledSectionT = React.Dispatch<
  React.SetStateAction<MenuItemsIDMenu | null>
>;

export type HookSectionPropsT = {
  setSections: React.Dispatch<React.SetStateAction<{} | SectionsQueueT>>;
};

type handleSetScrollSectionT = (
  sections: SectionsQueueT | {},
  currentOffset: number,
  setScrolledSection: setScrolledSectionT
) => void;
export const handleSetScrollSection: handleSetScrollSectionT = (
  sections,
  currentOffset,
  setScrolledSection
) => {
  const SAFE_ZONE = 5;
  const scrolledSection = Object.values(sections).findIndex(
    section =>
      currentOffset >= section.offset - SAFE_ZONE &&
      currentOffset < section.offset + section.height - SAFE_ZONE
  );
  setScrolledSection(
    scrolledSection !== -1
      ? (Object.keys(sections)[scrolledSection] as MenuItemsIDMenu)
      : null
  );
};
