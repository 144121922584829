import styled from "../styling/styled";
import { rem } from "../styling/theme";
import { AlignItemsT, FlexDirectionT, JustifyContentT } from "./FlexBox";

// width is similar as bootstrap grid system
type BlockStyleT = {
  paddingLeft?: number;
  paddingRight?: number;
  width?: number;
  withBorder?: boolean;
};

type FlexStyleT = {
  justifyContent?: JustifyContentT;
  flexDirection?: FlexDirectionT;
  alignItems?: AlignItemsT;
};

type GridWrapperStyleT = {
  opositeMargin?: number;
};

type ImageStyleT = {
  withMaxWidth?: boolean;
};

export const GridWrapper = styled.div<GridWrapperStyleT & FlexStyleT>`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ opositeMargin = 0 }): string => "0 " + rem(-opositeMargin)};
  ${({ theme }): string[] => [theme.media.maxMD]} {
    align-items: baseline;
    margin: 0 ${rem(-10)};
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    margin: 0;
  }
`;

export const Block = styled.div<BlockStyleT & FlexStyleT>`
  position: relative;
  width: ${({ width = 6 }): string => `${(width / 12) * 100}%`};
  padding: ${({ withBorder = false }): string =>
    withBorder ? rem(20) + " 0" : "0"};
  padding-left: ${({ paddingLeft = 0 }): string => rem(paddingLeft)};
  padding-right: ${({ paddingRight = 0 }): string => rem(paddingRight)};
  display: flex;
  flex-direction: ${({ flexDirection = "column" }): string => flexDirection};
  justify-content: ${({ justifyContent = "center" }): string => justifyContent};
  align-items: ${({ alignItems = "stretch" }): string => alignItems};
  border-left: ${({ withBorder = false, theme }): string =>
    withBorder ? "1px solid " + theme.colors.borderGray : "none"};

  &:first-of-type {
    border-left: none;
  }

  ${({ theme }): string[] => [theme.media.maxMD]} {
    width: ${({ width = 6 }): string => (width >= 5 ? "100%" : "50%")};
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
    border-left: none;
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Image = styled.img<ImageStyleT>`
  display: block;
  width: 100%;
  max-width: ${({ withMaxWidth = true }): string =>
    withMaxWidth ? rem(650) : "none"};
  ${({ theme }): string[] => [theme.media.maxM]} {
    max-width: ${({ withMaxWidth = false }): string =>
      withMaxWidth ? rem(450) : "none"};
    margin: 0 auto;
  }
`;
