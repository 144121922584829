import React, { FC } from "react";

type InnerHTMLT = {
  content: string;
};

const InnerHTML: FC<InnerHTMLT> = ({ content }) => (
  <span
    dangerouslySetInnerHTML={{
      __html: content
    }}
  />
);

export default InnerHTML;
