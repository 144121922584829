import { Link as GLink } from "gatsby-plugin-intl";
import React, { FC, ReactNode } from "react";

import styled from "../styling/styled";
import HoverText from "./HoverText";

const LinkBase = `
  display: inline-block;
  cursor: pointer;
`;

const StyledExternalLink = styled.a<LinkStyleProps>`
  color: ${({ theme }): string => theme.colors.white};
  ${LinkBase};
  span {
    text-decoration: ${({ underline }): string => underline};
  }
`;

const StyledLink = styled(GLink)<LinkStyleProps>`
  color: ${({ theme }): string => theme.colors.white};
  ${LinkBase};
  span {
    text-decoration: ${({ underline }): string => underline};
  }
`;

type LinkStyleProps = {
  underline: string;
};

type LinkT = {
  route?: string;
  externalRoute?: string;
  withHoverEffect?: boolean;
  underline?: string;
  className?: string;
};

type handleHoverEffectRenderT = (
  withHoverEffect: boolean,
  children: ReactNode
) => ReactNode;
const handleHoverEffectRender: handleHoverEffectRenderT = (
  withHoverEffect,
  children
) => {
  if (withHoverEffect) {
    return <HoverText>{children}</HoverText>;
  }

  return children;
};

const Link: FC<LinkT> = ({
  children,
  route,
  externalRoute,
  withHoverEffect = true,
  underline = "none",
  className
}) => {
  if (externalRoute) {
    return (
      <StyledExternalLink
        underline={underline.toString()}
        href={externalRoute}
        target="_blank"
        className={className}
      >
        {handleHoverEffectRender(withHoverEffect, children)}
      </StyledExternalLink>
    );
  }

  return (
    <StyledLink
      underline={underline.toString()}
      to={route || "/"}
      className={className}
    >
      {handleHoverEffectRender(withHoverEffect, children)}
    </StyledLink>
  );
};

export default Link;
