import React, { FC, ReactNode } from "react";

import { LeftOpositeMargin } from "../../components/Container";
import Heading from "../../components/Heading";
import MovingBlock from "../../components/MovingBlock";
import Paragraph from "../../components/Paragraph";
import Spacer from "../../components/Spacer";
import Texture from "../../components/Texture";
import { useResize } from "../../hooks/useResize";
import styled from "../../styling/styled";
import { rem, theme } from "../../styling/theme";

export const PARAGRAPH_WIDTH = 450;

const Wrapper = styled.div`
  position: relative;
`;

type getContentT = (
  paragraph: string | string[],
  color: string,
  maxWidth: number
) => ReactNode;
const getContent: getContentT = (paragraph, color, maxWidth) => {
  if (Array.isArray(paragraph)) {
    return (
      <>
        {paragraph.map((item, index) => (
          <Paragraph
            key={index}
            color={color}
            maxWidth={maxWidth}
            paragraph={item}
          />
        ))}
      </>
    );
  }

  return <Paragraph color={color} maxWidth={maxWidth} paragraph={paragraph} />;
};

type HeadingBlockT = {
  heading: string;
  pre?: string;
  paragraph?: string | string[];
  color?: string;
  symbol?: string | null;
  withoutBlock?: boolean;
  maxWidth?: number;
};

const HeadingBlock: FC<HeadingBlockT> = ({
  heading,
  pre,
  paragraph,
  children,
  color = theme.colors.darkText,
  symbol = "{",
  withoutBlock = false,
  maxWidth = PARAGRAPH_WIDTH
}) => {
  const { mobileVersion } = useResize();
  return (
    <Wrapper>
      <Wrapper>
        {!withoutBlock && (
          <LeftOpositeMargin>
            <MovingBlock open={false} top={-10} layer={0}>
              <Texture
                withColor={theme.colors.primary}
                width={rem(400)}
                height={rem(mobileVersion ? 95 : 125)}
              />
            </MovingBlock>
          </LeftOpositeMargin>
        )}
        <Heading
          symbolColor={color}
          pre={pre}
          title={heading}
          symbol={symbol}
        />
        <Spacer size="extraSmall" />
        {paragraph && getContent(paragraph, color, maxWidth)}
        {children && children}
      </Wrapper>
    </Wrapper>
  );
};

export default HeadingBlock;
