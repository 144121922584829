import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { SuccessStoriesIdsT } from "../partials/SuccessStories/data";
import styled from "../styling/styled";
import { theme as importedTheme, rem } from "../styling/theme";
import Link from "./Link";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Heading = styled.h1`
  font-family: "Overpass Mono";
  font-size: ${rem(80)};
  line-height: ${rem(100)};
  font-weight: 700;
  letter-spacing: 15px;
  display: inline-block;
  width: 100%;
  margin-top: ${rem(0)};
  margin-bottom: ${rem(20)};
  ${({ theme }): string[] => [theme.media.maxMD]} {
    font-size: ${rem(60)};
    line-height: ${rem(75)};
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    font-size: ${rem(40)};
    line-height: ${rem(55)};
    letter-spacing: 5px;
  }
`;

const Item = styled(Link)<ItemStylePropsT>`
  font-size: ${rem(24)};
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme }): string => theme.colors.white};
  ${({ theme }): string[] => [theme.media.maxS]} {
    font-size: ${rem(18)};
  }

  &:after {
    content: "/";
    color: ${({ color }): string => color};
    margin: 0 ${rem(20)};

    ${({ theme }): string[] => [theme.media.maxS]} {
      margin: 0 ${rem(10)};
    }
  }
`;

const Existance = styled.p`
  opacity: 0.6;
  font-size: ${rem(16)};
  width: 100%;
  margin-top: 0;
  margin-bottom: ${rem(30)};
`;

const Description = styled.p`
  opacity: 0.8;
  font-size: ${rem(18)};
  margin-top: 0;
`;

type ItemStylePropsT = {
  color: string;
};

type FirstLevelT = "home";
type SecondLevelT = "successStorries";
type ThirdLevelT = SuccessStoriesIdsT;

type BreadcrumbItemT = {
  title: string;
  href: string;
};

type BreadcrumbItemsT = {
  firstLevel: { [key in FirstLevelT]: BreadcrumbItemT };
  secondLevel: { [key in SecondLevelT]: BreadcrumbItemT };
  thirdLevel: { [key in ThirdLevelT]: BreadcrumbItemT };
};

const breadcrumbItems: BreadcrumbItemsT = {
  firstLevel: {
    home: {
      title: "BOOT!Q",
      href: "/"
    }
  },
  secondLevel: {
    successStorries: {
      title: "SUCCESS STOR!ES",
      href: "/success-stories"
    }
  },
  thirdLevel: {
    "4finance": {
      title: "4finance",
      href: "/4finacne"
    },
    moplay: {
      title: "MoPlay",
      href: "/moplay"
    },
    metrostav: {
      title: "METROSTAV",
      href: "/metrostav"
    },
    weeducate: {
      title: "WEEDUCATE",
      href: "/moplay"
    },
    cemex: {
      title: "CEMEX GO",
      href: "/cemex"
    },
    meatbones: {
      title: "Meat & Bones",
      href: "/meatbones"
    },
    itsounds: {
      title: "itSOUNDS",
      href: "/itsounds"
    }
  }
};

export type BradcrumItemsT = [FirstLevelT, SecondLevelT, ThirdLevelT?];

type BreadcrumbHeadingT = {
  items: BradcrumItemsT;
  color?: string;
  description?: string;
  existence?: number[] | null;
};

const BreadcrumbHeading: FC<BreadcrumbHeadingT> = ({
  items,
  color = importedTheme.colors.primary,
  description,
  existence
}) => {
  const intl = useIntl();

  const [firstLevel, secondLevel, thirdLevel] = items;
  const firstLevelItem = breadcrumbItems.firstLevel[firstLevel];
  const secondLevelItem = breadcrumbItems.secondLevel[secondLevel];
  const thirdLevelItem = thirdLevel && breadcrumbItems.thirdLevel[thirdLevel];

  return (
    <Wrapper>
      <Item color={color} route={firstLevelItem.href}>
        {firstLevelItem.title}
      </Item>
      {thirdLevelItem ? (
        <Item color={color} route={secondLevelItem.href}>
          {secondLevelItem.title}
        </Item>
      ) : (
        <Heading>{secondLevelItem.title}</Heading>
      )}
      {thirdLevelItem && <Heading>{thirdLevelItem.title}</Heading>}

      {existence && (
        <Existance>
          {existence[0]} -{" "}
          {existence[1] ||
            intl.formatMessage({
              id: "successStories.now"
            })}
        </Existance>
      )}
      {description && <Description>{description}</Description>}
    </Wrapper>
  );
};

export default BreadcrumbHeading;
