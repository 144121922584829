import React, { FC, useEffect, useState } from "react";

import { SIDEBAR_EASING } from "../partials/SideBar";
import styled from "../styling/styled";

const HoverTextStyled = styled.span<HoverTextStyleProps>`
  opacity: ${({ hover, disable }): number => (hover ? 1 : disable ? 1 : 0.5)};
  transition: ${({ transition }): string => "opacity 350ms " + transition};
  height: 100%;
  display: inline-block;
`;

type HoverTextStyleProps = {
  hover: boolean;
  disable: boolean;
  transition: string;
};

type HoverTextT = {
  manualHover?: boolean;
  disable?: boolean;
};

const HoverText: FC<HoverTextT> = ({
  children,
  disable = false,
  manualHover = false
}) => {
  const [hover, setHover] = useState(false);

  useEffect(() => {
    setHover(manualHover);
  }, [manualHover]);

  return (
    <HoverTextStyled
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
      hover={hover}
      disable={disable}
      transition={SIDEBAR_EASING}
    >
      {children}
    </HoverTextStyled>
  );
};

export default HoverText;
