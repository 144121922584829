import { useIntl } from "gatsby-plugin-intl";
import React, { FC, ReactNode, useLayoutEffect, useState } from "react";

import {
  CemexHeader,
  FourFinanceHeader,
  ItsoundsHeader,
  MeatbonesHeader,
  MetrostavHeader,
  MoPlayHeader,
  WeeducateHeader
} from "../../assets/images/Images";
import BreadcrumbHeading, {
  BradcrumItemsT
} from "../../components/BreadcrumbHeading";
import Container from "../../components/Container";
import ScrollDown from "../../components/ScrollDown";
import { useResize } from "../../hooks/useResize";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import { SIDEBAR_WIDTH } from "../SideBar";
import { SuccessStoriesIdsT, getColor } from "../SuccessStories/data";

const Wrapper = styled.div<WrapperStyleProps>`
  height: ${({ height }): string => height};
  margin-left: ${rem(-SIDEBAR_WIDTH)};
  padding-left: ${rem(SIDEBAR_WIDTH)};
  background-color: ${({ theme }): string => theme.colors.black};
  background-image: ${({ background }): string => "url(" + background + ")"};
  background-size: cover;
  color: ${({ theme }): string => theme.colors.white};
`;

const IntroWrapper = styled.div<IntroWrapperStyleT>`
  display: flex;
  flex-direction: column;
  max-width: ${({ picture }): string => (picture ? "50%" : "100%")};
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: ${rem(50)};
  position: relative;

  ${({ theme }): string[] => [theme.media.maxMD]} {
    max-width: 100%;
  }
`;

export const CemexGoLogo = styled.img`
  position: absolute;
  width: 100%;
  max-width: ${rem(270)};
  left: 120%;
  ${({ theme }): string[] => [theme.media.maxMD]} {
    display: none;
  }
`;

export const WeeducatePersonImage = styled.img`
  position: absolute;
  width: 100%;
  max-width: ${rem(450)};
  left: 110%;
  ${({ theme }): string[] => [theme.media.maxLg]} {
    max-width: ${rem(300)};
    left: 140%;
  }
  ${({ theme }): string[] => [theme.media.maxMD]} {
    position: relative;
    left: auto;
    max-width: ${rem(200)};
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    max-width: ${rem(150)};
  }
`;

export const ItSoundsSpeakerImage = styled.img`
  position: absolute;
  width: 100%;
  max-width: ${rem(314)};
  left: 125%;
  ${({ theme }): string[] => [theme.media.maxLg]} {
    max-width: ${rem(280)};
    left: 140%;
  }
  ${({ theme }): string[] => [theme.media.maxMD]} {
    position: relative;
    left: auto;
    max-width: ${rem(100)};
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    max-width: ${rem(75)};
  }
`;

type WrapperStyleProps = {
  height: string;
  background: string;
};

type CaseStudyIntroductionItemT = {
  items: BradcrumItemsT;
  existence: number[] | null;
  background: string;
};

type IntroductionT = {
  caseStudyId: SuccessStoriesIdsT;
  picture?: ReactNode;
};

type IntroWrapperStyleT = {
  picture?: ReactNode;
};

type CaseStudiesT = { [key in SuccessStoriesIdsT]: CaseStudyIntroductionItemT };

const caseStudies: CaseStudiesT = {
  moplay: {
    items: ["home", "successStorries", "moplay"],
    existence: [2017],
    background: MoPlayHeader
  },
  "4finance": {
    items: ["home", "successStorries", "4finance"],
    existence: [2016, 2018],
    background: FourFinanceHeader
  },
  cemex: {
    items: ["home", "successStorries", "cemex"],
    existence: [2017],
    background: CemexHeader
  },
  metrostav: {
    items: ["home", "successStorries", "metrostav"],
    existence: [2017],
    background: MetrostavHeader
  },
  weeducate: {
    items: ["home", "successStorries", "weeducate"],
    existence: null,
    background: WeeducateHeader
  },
  meatbones: {
    items: ["home", "successStorries", "meatbones"],
    existence: null,
    background: MeatbonesHeader
  },
  itsounds: {
    items: ["home", "successStorries", "itsounds"],
    existence: null,
    background: ItsoundsHeader
  }
};

const Introduction: FC<IntroductionT> = ({ caseStudyId, picture }) => {
  const [containerHeight, setContainerHeight] = useState("100vw");
  const { mobileVersion, windowHeight } = useResize();
  const intl = useIntl();

  useLayoutEffect(() => {
    setContainerHeight(rem(windowHeight));
  }, [windowHeight]);

  return (
    <Wrapper
      background={caseStudies[caseStudyId].background}
      height={mobileVersion ? rem(550) : containerHeight}
    >
      <Container height="100%">
        <IntroWrapper picture={picture}>
          {!mobileVersion && <ScrollDown />}
          <BreadcrumbHeading
            color={getColor(caseStudyId)}
            items={caseStudies[caseStudyId].items}
            existence={caseStudies[caseStudyId].existence}
            description={intl.formatMessage({
              id: `${caseStudyId}.description`
            })}
          />
          {picture && picture}
        </IntroWrapper>
      </Container>
    </Wrapper>
  );
};

export default Introduction;
