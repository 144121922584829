/* eslint-disable @typescript-eslint/no-var-requires */
export const LogoShortSVG = require("./logo_short.svg");
export const LogoVerticalSVG = require("./logo_vertical.svg");
export const IconBack = require("./icon_back_button.svg");
export const IconHome = require("./icon_home.svg");

export const AtlassianSymbol = require("./atlassian.svg");
export const BusinessInteligenceSymbol = require("./businessInteligence.svg");
export const BusinessSymbol = require("./business.svg");
export const CommunicationToolsSymbol = require("./comunicationtools.svg");
export const CloseIcon = require("./close.svg");
export const CrmSymbol = require("./crm.svg");
export const CustomDevelopmentSymbol = require("./custom_development.svg");
export const CustomerSupportSymbol = require("./customerSupport.svg");
export const DesignSymbol = require("./design.svg");
export const DevelopmentSymbol = require("./development.svg");
export const ECommerceSymbol = require("./eCommerce.svg");
export const IOTSymbol = require("./iot.svg");
export const InfrastructureSymbol = require("./infrastructure.svg");
export const MachineLearningSymbol = require("./machineLearning.svg");
export const MobileAplicationSymbol = require("./mobile.svg");
export const SapSymbol = require("./sap.svg");
export const SysOpsSymbol = require("./sysOps.svg");
export const TeamLeasingSymbol = require("./teamLeasing.svg");
export const TestingSymbol = require("./testing.svg");

export const CaseStudiesShape = require("./caseStudiesShape.svg");

export const TranslationServerSymbol = require("./logo_translationServer.svg");
export const ReactSymbol = require("./logo_react.svg");
export const NightwatchJsSymbol = require("./logo_nightwatch.svg");
export const ECMAScript2015Symbol = require("./logo_es6.svg");
export const ContentfulCMSSymbol = require("./logo_contentful.svg");
export const ChefSymbol = require("./logo_chef.svg");

// JPG, PNG etc.
export const OgImage = require("../../../static/images/og-image.png");
export const TextureTemplate = require("../../../static/images/background_texture.png");
export const TextureTemplateWhite = require("../../../static/images/background_texture_white.png");
export const ConcreteTemplate = require("../../../static/images/concrete_texture.jpg");
export const ConcreteTemplate2 = require("../../../static/images/concrete_texture2.jpg");
export const LogoMask = require("../../../static/images/logo_mask.png");
export const VideoThumb = require("../../../static/images/video.png");
export const Appstore = require("../../../static/images/appstore.png");
export const KpmLogo = require("../../../static/images/logo_kpm.png");
export const AtlassianLogo = require("../../../static/images/logo_atlassian.png");
export const AwsLogo = require("../../../static/images/logo_aws.png");
export const KenticoLogo = require("../../../static/images/logo_kentico.png");
export const MicrosoftLogo = require("../../../static/images/logo_microsoft.png");
export const ApifyLogo = require("../../../static/images/logo_apify.png");
export const GoodDataLogo = require("../../../static/images/logo_goodData.png");
export const KeboolaLogo = require("../../../static/images/logo_keboola.png");
export const ModalBg = require("../../../static/images/modal_bg_opacity.png");
export const FooterBg = require("../../../static/images/footer_bg.png");
export const FooterShadowBg = require("../../../static/images/footer_shadow.png");

export const BratislavaCity = require("../../../static/images/city_bratislava.jpg");
export const JeseniceCity = require("../../../static/images/city_jesenice.jpg");
export const JihlavaCity = require("../../../static/images/city_jihlava.jpg");
export const KosiceCity = require("../../../static/images/city_kosice.jpg");
export const NitraCity = require("../../../static/images/city_nitra.jpg");
export const OlomoucCity = require("../../../static/images/city_olomouc.jpg");
export const OstravaCity = require("../../../static/images/city_ostrava.jpg");
export const PilsenCity = require("../../../static/images/city_pilsen.jpg");
export const PopradCity = require("../../../static/images/city_poprad.jpg");
export const PragueCity = require("../../../static/images/city_prague.jpg");
export const TrencinCity = require("../../../static/images/city_trencin.jpg");

export const MoPlayThumbnail = require("../../../static/images/caseStudies/moplay_thumb.png");
export const MoPlayHeader = require("../../../static/images/caseStudies/moplay_header.jpg");
export const MoPlayEnter1 = require("../../../static/images/caseStudies/moplay_enter_1.jpg");
export const MoPlayEnter2 = require("../../../static/images/caseStudies/moplay_enter_2.jpg");
export const MoPlayGraph = require("../../../static/images/caseStudies/moplay_graph.png");
export const MoPlayApp = require("../../../static/images/caseStudies/moplay_app.png");
export const MoPlayBlock1 = require("../../../static/images/caseStudies/moplay_block_1.jpg");
export const MoPlayBlock2 = require("../../../static/images/caseStudies/moplay_block_2.jpg");
export const MoPlayBlock3 = require("../../../static/images/caseStudies/moplay_block_3.jpg");
export const CemexThumbnail = require("../../../static/images/caseStudies/cemex_thumb.png");
export const CemexHeader = require("../../../static/images/caseStudies/cemex_header.jpg");
export const CemexEnter1 = require("../../../static/images/caseStudies/cemex_enter_1.jpg");
export const CemexEnter2 = require("../../../static/images/caseStudies/cemex_enter_2.jpg");
export const CemexLogoGo = require("../../../static/images/caseStudies/cemex_go_logo.png");
export const CemexGraph = require("../../../static/images/caseStudies/cemex_graph.png");
export const CemexLogoFortune = require("../../../static/images/caseStudies/cemex_logo_fortune.png");
export const CemexBlock1 = require("../../../static/images/caseStudies/cemex_block_1.jpg");
export const CemexBlock2 = require("../../../static/images/caseStudies/cemex_block_2.jpg");
export const CemexBlock3 = require("../../../static/images/caseStudies/cemex_block_3.jpg");
export const CemexScreen1 = require("../../../static/images/caseStudies/cemex_screen_1.png");
export const CemexScreen2 = require("../../../static/images/caseStudies/cemex_screen_2.png");
export const CemexScreen3 = require("../../../static/images/caseStudies/cemex_screen_3.png");
export const CemexScreen4 = require("../../../static/images/caseStudies/cemex_screen_4.png");
export const CemexTetris1 = require("../../../static/images/caseStudies/cemex_tetris_1.jpg");
export const CemexTetris2 = require("../../../static/images/caseStudies/cemex_tetris_2.jpg");
export const CemexTetris3 = require("../../../static/images/caseStudies/cemex_tetris_3.jpg");
export const CemexTetris4 = require("../../../static/images/caseStudies/cemex_tetris_4.jpg");
export const CemexLogoFiancial = require("../../../static/images/caseStudies/cemex_logo_financial.png");
export const CemexLogoForbes = require("../../../static/images/caseStudies/cemex_logo_forbes.png");
export const CemexLogoNYT = require("../../../static/images/caseStudies/cemex_logo_nyt.png");
export const CemexLogoWire = require("../../../static/images/caseStudies/cemex_logo_wire.png");
export const CemexLogoSAP = require("../../../static/images/caseStudies/cemex_sap.png");
export const FourFinanceThumbnail = require("../../../static/images/caseStudies/fourfinance_thumb.png");
export const FourFinanceHeader = require("../../../static/images/caseStudies/fourfinance_header.png");
export const FourFinanceEnter1 = require("../../../static/images/caseStudies/fourfinance_enter_1.png");
export const FourFinanceEnter2 = require("../../../static/images/caseStudies/fourfinance_enter_2.png");
export const FourFinanceEffective = require("../../../static/images/caseStudies/fourfinance_effective.png");
export const MetrostavThumbnail = require("../../../static/images/caseStudies/metrostav_thumb.png");
export const MetrostavHeader = require("../../../static/images/caseStudies/metrostav_header.png");
export const MetrostavBlock1 = require("../../../static/images/caseStudies/metrostav_block_1.png");
export const MetrostavBlock2 = require("../../../static/images/caseStudies/metrostav_block_2.png");
export const MetrostavScreen = require("../../../static/images/caseStudies/metrostav_screen.png");
export const MetrostavPrototype = require("../../../static/images/caseStudies/metrostav_prototype.png");
export const MetrostavConcrete = require("../../../static/images/caseStudies/metrostav_concrete.png");
export const MetrostavUIScreen = require("../../../static/images/caseStudies/metrostav_ui_screen.png");
export const MetrostavUIScreenMobile = require("../../../static/images/caseStudies/metrostav_ui_screen_mobile.png");
export const MetrostavImplement = require("../../../static/images/caseStudies/metrostav_implement.png");
export const MetrostavBackgroundScreens = require("../../../static/images/caseStudies/metrostav_background_screens.png");
export const WeeducateThumbnail = require("../../../static/images/caseStudies/weeducate_thumb.png");
export const WeeducateHeader = require("../../../static/images/caseStudies/weeducate_header.jpg");
export const WeeducateAppScreen1 = require("../../../static/images/caseStudies/weeducate_app_screen_1.jpg");
export const WeeducateAppScreen2 = require("../../../static/images/caseStudies/weeducate_app_screen_2.png");
export const WeeducateAppScreen3 = require("../../../static/images/caseStudies/weeducate_app_screen_3.png");
export const WeeducateAppScreen4 = require("../../../static/images/caseStudies/weeducate_app_screen_4.jpg");
export const WeeducateAppScreen5 = require("../../../static/images/caseStudies/weeducate_app_screen_5.jpg");
export const WeeducatePerson = require("../../../static/images/caseStudies/weeducate_person.png");
export const WeeducateScreen = require("../../../static/images/caseStudies/weeducate_screen.png");
export const WeeducateWalkthrough = require("../../../static/images/caseStudies/weeducate_walkthrough.png");
export const MeatbonesHeader = require("../../../static/images/caseStudies/meatbones_header.jpg");
export const MeatbonesScreen1 = require("../../../static/images/caseStudies/MeatbonesScreen1.jpg");
export const MeatbonesScreen2 = require("../../../static/images/caseStudies/MeatbonesScreen2.png");
export const MeatbonesThumbnail = require("../../../static/images/caseStudies/meatbones_thumb.png");
export const ItsoundsHeader = require("../../../static/images/caseStudies/itsounds_header.jpg");
export const ItsoundsScreen1 = require("../../../static/images/caseStudies/itsounds_screen1.png");
export const ItsoundsScreen2 = require("../../../static/images/caseStudies/itsounds_screen2.png");
export const ItsoundsScreen3 = require("../../../static/images/caseStudies/itsounds_screen3.png");
export const ItsoundsScreen4 = require("../../../static/images/caseStudies/itsounds_screen4.png");
export const ItsoundsSpeaker1 = require("../../../static/images/caseStudies/itsounds_speaker1.png");
export const ItsoundsSpeaker2 = require("../../../static/images/caseStudies/itsounds_speaker2.png");
export const ItsoundsThumbnail = require("../../../static/images/caseStudies/itsounds_thumb.png");
export const ViewMoreThumbnail = require("../../../static/images/caseStudies/viewmore_black.png");

export const AppiumLogo = require("../../../static/images/technologiesLogos/logo_appium.png");
export const EslintLogo = require("../../../static/images/technologiesLogos/logo_eslint.png");
export const FlowLogo = require("../../../static/images/technologiesLogos/logo_flow.png");
export const I18nLogo = require("../../../static/images/technologiesLogos/logo_i18n.png");
export const JestLogo = require("../../../static/images/technologiesLogos/logo_jest.png");
export const LernaLogo = require("../../../static/images/technologiesLogos/logo_lerna.png");
export const LottieLogo = require("../../../static/images/technologiesLogos/logo_lottie.png");
export const ReactNativeWebLogo = require("../../../static/images/technologiesLogos/logo_react_native_web.png");
export const ReactNativeLogo = require("../../../static/images/technologiesLogos/logo_react_native.png");
export const ReactNavigationLogo = require("../../../static/images/technologiesLogos/logo_react_navigation.png");
export const ReactRouterLogo = require("../../../static/images/technologiesLogos/logo_react_router.png");
export const ReduxLogo = require("../../../static/images/technologiesLogos/logo_redux.png");
export const ReselectLogo = require("../../../static/images/technologiesLogos/logo_reselect.png");
export const StorybookLogo = require("../../../static/images/technologiesLogos/logo_storybook.png");
