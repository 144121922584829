type putEmptyLineInsteadSpaceT = (str: string, spaceOrder: number) => string;
export const putEmptyLineInsteadSpace: putEmptyLineInsteadSpaceT = (
  str,
  spaceOrder
) => {
  const newString = str
    .split(" ")
    .map((item, index) =>
      index === spaceOrder ? "<br />" + item : firtsLetterCheck(item, index)
    )
    .join("");

  return newString;
};

type firtsLetterCheckT = (str: string, index: number) => string;
const firtsLetterCheck: firtsLetterCheckT = (str, index) =>
  index === 0 ? str : " " + str;
